<script setup>
import { inject, ref } from 'vue';
import { Notify, NoticeBar } from 'vant';

const _this = inject('plugin');
const ctx = inject('ctx');
const $require = inject('$require');
let pageData = ref(null);
let loading = ref(false);
let finished = ref(false);
let page_num = ref(1);
let page_size = ref(10);
let total = ref(0);
let addressList = ref([]);

/***
 * 获取合同列表
 */
let getList = () => {
  $require
    .HttpPost('/h5/contract/getContractList', {
      page_num: page_num.value,
      page_size: page_size.value
    })
    .then((res) => {
      console.log(res);
      pageData.value = res.data.list;
      // res.data.list.forEach(item => {
      //   console.log(item);
      // })

      loading.value = false;
    });
};

// 获取已签数量
function getGetContraTotal () {
  $require.HttpGet('/h5/contract/getContraTotal').then((res) => {
    total.value = res.data;
  });
}
getGetContraTotal();
getList();
/**
 * 获取续签地址
 */
$require.HttpPost('/h5/contract/contractAddress').then((res) => {
  addressList.value = res.data;
});
/**
 * 上拉加载
 */
let onLoad = () => {
  loading.value = false;
  // console.log(arr.length)
  if (page_num.value >= Math.ceil(total.value / page_size.value)) {
    finished.value = true;
  } else {
    page_num.value++;
    getList();
  }
};

//   续签
function continueSign (item) {
  _this
    .myconfirm(`您的合同还有${item.differ_day}天到期，确认是否申请线上续签合同`, '续签确认')
    .then((res) => {
      $require
        .HttpPost('/h5/contract/sendRenew', {
          contract_id: item.contract_id
        })
        .then((res) => {
          getList();
        });
    })
    .catch(() => { });
}

//   线下续签地址
let offxq = ref(null);

function getOffaddress () {
  offxq.value.fade();
}

// copy
function copy (data) {
  let transfer = document.createElement('input');
  document.body.appendChild(transfer);
  transfer.value = data;
  transfer.focus();
  transfer.select();
  if (document.execCommand('copy')) {
    document.execCommand('copy');
  }
  transfer.blur();
  document.body.removeChild(transfer);
  offxq.value.close();
  Notify({
    type: 'success',
    message: '复制成功',
    position: 'top'
  });
}

// 跳转续签合同
function goUrl (url) {
  ctx.router.push(url);
}
</script>
<template>
  <div class="contract">
    <div class="num">
      <span>已签合同</span>
      <span>({{ total }}份)</span>
    </div>
    <van-list v-model:loading="loading" :finished="finished" :offset="30" finished-text="没有更多了" @load="onLoad">
      <div class="item" v-for="(item, index) in pageData" @click="goUrl(`/account/contractDetail?id=${item.contract_id}`)"
        :key="index">
        <div class="title">
          <div>商铺租赁合同</div>
          <span v-if="item.contract_status == 1">历史</span>
          <div v-else>
            <span>剩余时间</span>
            <span :style="{ color: item.differ_day <= 50 ? '#FD6336' : '#85C226' }">{{
              item.differ_day
            }}</span>
            <span>天</span>
          </div>
        </div>

        <div class="info">
          <!-- <div class="notice">
                        <img src="@/assets/image/my/xiaoxi.png" alt />
                        <span>合同即将到期，请及时进行续签</span>
          </div>-->
          <van-notice-bar v-if="item.property_logs" style="padding: 0" left-icon="volume-o" color="#FD6336"
            ackground="#ffffff" :text="item.property_logs[item.property_logs.length - 1].property_content" />
          <div>
            <span>合同期限:</span>
            <span>{{ item.contract_start }} 至 {{ item.contract_end }}</span>
          </div>
          <div class="rentstall">
            <span>商户姓名:</span>
            <span>{{ item.contract_name }}</span>
          </div>
          <div class="rentstall">
            <span>租赁商铺:</span>
            <span>{{ item.merchants_stalls }}</span>
          </div>
        </div>
        <!-- xq -->
        <div class="offxq">
          <div @click.stop="getOffaddress">
            <template v-if="item.contract_status == 10 && item.differ_day <= 15">
              <img src="@/assets/image/my/dz.png" alt />
              <span>线下续签地址</span>
            </template>
          </div>
          <!-- <div class="xqbtn" v-if="item.contract_renewal == 10 && item.contract_status == 10"
            @click.stop="continueSign(item)">
            确认续签
          </div> -->
          <!-- <div
            v-if="item.contract_status == 10 && item.contract_type == 10 && item.contract_renewal == 30"
            class="xqbtn"
            @click.stop="goUrl(`/account/contractDetail?id=${item.contract_id}`)"
          >续签合同</div> -->
          <div v-if="item.contract_status == 0 && item.contract_type == 10 && item.contract_is_agree == 12 && item.contract_user_status == 0 && item.contract_user_is_agree == 0
            " class="xqbtn" @click.stop="goUrl(`/account/contractDetail?id=${item.contract_id}`)">
            签字确认
          </div>
          <div
            v-if="item.contract_status == 0 && item.contract_type == 10 && item.contract_is_agree == 12 && item.contract_user_status == 0 && item.contract_user_is_agree == 20"
            class="xqbtn" @click.stop="goUrl(`/account/contractDetail?id=${item.contract_id}`)">
            重新签字
          </div>
        </div>
      </div>
    </van-list>

    <rConfirm slots ref="offxq" :btn="false" titles="线下续签地址">
      <div class="addresslist">
        <div class="tip">(请前往下列地址进行线下续签合同)</div>
        <div class="list" v-for="(item, index) in addressList" :key="index">
          <span>地址{{ index + 1 }}:</span>
          <img src="@/assets/image/my/dz.png" alt />
          <span>{{ item.content }}</span>
          <span @click="copy(item.content)">复制</span>
        </div>
      </div>
    </rConfirm>

    <rConfirm slots ref="offxq" :btn="false" titles="线下续签地址">
      <div class="addresslist">
        <div class="tip">(请前往下列地址进行线下续签合同)</div>
        <div class="list" v-for="(item, index) in addressList" :key="index">
          <span>地址{{ index + 1 }}:</span>
          <img src="@/assets/image/my/dz.png" alt />
          <span>{{ item.content }}</span>
          <span @click="copy(item.content)">复制</span>
        </div>
      </div>
    </rConfirm>
  </div>
</template>
<style lang="scss">
.contract {
  padding: 29px 24px 50px 24px;
  box-sizing: border-box;

  .num {
    font-size: 28px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #9a9a9a;
    padding-left: 40px;
    box-sizing: border-box;
    margin-bottom: 40px;
  }

  .item {
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 0px 25px 0px rgba(57, 57, 57, 0.06);
    border-radius: 20px;
    margin-bottom: 20px;
    padding-bottom: 40px;

    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 51px;
      box-sizing: border-box;
      height: 96px;
      border-bottom: 1px solid #efefef;

      span {
        font-size: 24px;
      }

      div {
        display: flex;
        align-items: center;

        &:nth-child(1) {
          font-size: 30px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #000000;
        }

        span {
          font-size: 24px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #cacaca;

          &:nth-child(2) {
            font-size: 30px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #85c226;
            margin: 0 17px;
          }
        }
      }
    }

    .info {
      // height: px;
      font-size: 28px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #8d8d8d;
      padding: 0 39px;
      box-sizing: border-box;
      margin-top: 20px;

      div {
        span {
          &:nth-child(1) {
            width: 120px;
            display: inline-block;
          }

          &:nth-child(2) {
            font-weight: 500;
            color: #000000;
            margin-left: 19px;
          }
        }
      }

      .notice {
        img {
          width: 28px;
          height: 28px;
        }

        span {
          width: 577px;
          height: 60px;
          line-height: 60px;
          background: #f9f9f9;
          font-size: 24px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #fd6336 !important;
          display: inline-block;
          padding-left: 14px;
          box-sizing: border-box;
          margin-left: 8px;
        }
      }
    }

    .rentstall {
      display: flex;

      &:nth-child(2) {
        margin: 29px 0;
      }

      span {
        &:nth-child(1) {
          width: 80px;
        }

        &:nth-child(2) {
          flex: 1;
          overflow: auto;
        }
      }
    }

    .offxq {
      font-size: 28px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #93c93e;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 39px;
      box-sizing: border-box;
      vertical-align: middle;
      margin-top: 20px;

      img {
        width: 28px;
        height: 28px;
        margin-right: 11px;
      }

      span {
        vertical-align: middle;
      }

      .xqbtn {
        width: 160px;
        height: 60px;
        background: #85c226;
        border-radius: 30px;
        text-align: center;
        line-height: 60px;
        font-size: 28px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #ffffff;
      }
    }
  }

  .addresslist {
    font-size: 28px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #000000;
    padding: 20px 40px;
    box-sizing: border-box;

    .tip {
      font-size: 24px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #adadad;
      line-height: 42px;
      width: 100%;
      text-align: center;
      margin-bottom: 60px;
    }

    .list {
      margin-bottom: 41px;
      display: flex;

      // align-items: center;
      // flex-wrap: wrap;
      span {
        &:nth-child(1) {
          flex-shrink: 0;
          width: 80px;
        }

        &:nth-child(3) {
          //  line-height: 50px;
          vertical-align: middle;
        }

        &:nth-child(4) {
          width: 100px;
          font-size: 28px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #85c226;
          margin-left: 21px;
        }
      }
    }

    img {
      width: 25px;
      height: 28px;
      margin: 0 9px 0 20px;
      line-height: 50px;
    }
  }
}
</style>
